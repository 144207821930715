<template>
  <v-app>
    <!-- Header Fixo -->
    <v-app-bar color="black" dark fixed>
      <v-toolbar-title class="white--text">
        <!-- Clique no logo para ir para a HomePage -->
        <router-link to="/" style="text-decoration: none;">
          <img src="/TechComH.png" alt="TechComH Logo" class="header-logo" style="cursor: pointer;" />
        </router-link>
      </v-toolbar-title>

      <!-- Redes Sociais -->
      <v-btn icon @click="openLink('https://www.instagram.com/techcomh')">
        <v-icon size="24px" color="white">mdi-instagram</v-icon>
      </v-btn>

      <v-btn icon @click="openLink('https://twitter.com/techcomh')">
        <v-icon size="24px" color="white">mdi-twitter</v-icon>
      </v-btn>

      <v-btn icon @click="openLink('https://www.youtube.com/@TechComH')">
        <v-icon size="24px" color="white">mdi-youtube</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Conteúdo da Página -->
    <v-main class="main-content">
      <router-view></router-view> <!-- Aqui serão renderizados os componentes das rotas -->
    </v-main>

    <!-- Footer -->
    <v-footer color="black" dark>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="12" sm="6">
            <span>&copy; 2024 TechComH - Todos os direitos reservados.</span>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-right">
            <router-link to="/privacy-policy" class="footer-link">Política de Privacidade</router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped>
.header-logo {
  height: 54px;
}

.main-content {
  margin-top: 10px;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .text-sm-right {
    text-align: left !important;
    margin-top: 10px;
  }
}
</style>
