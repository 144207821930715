import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

// Suas credenciais de configuração do Firebase
const firebaseConfig = {
    apiKey: "AIzaSyC4-N2R2sDpwXxhhvYfQzT7tKGyYxtAMag",
    authDomain: "techcomh-e9694.firebaseapp.com",
    projectId: "techcomh-e9694",
    storageBucket: "techcomh-e9694.appspot.com",
    messagingSenderId: "110614045927",
    appId: "1:110614045927:web:4fd7f940c436193178ae42",
    measurementId: "G-MSF1H684CY"
};

// Inicializar
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
