<template>
  <v-container class="mt-10">
    <!-- Notícias em Destaque -->
    <FeaturedNews />

    <!-- Mais Notícias -->
    <v-row>
      <v-col cols="12">
        <p style="margin-bottom:10px">mais notícias</p>
        <hr style="border: 2px solid #f8a602; margin-bottom:20px">
        <NewsList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FeaturedNews from "@/components/FeaturedNews.vue";
import NewsList from "@/components/NewsList.vue";

export default {
  name: "HomePage",
  components: {
    FeaturedNews,
    NewsList
  }
};
</script>

<style scoped>
/* Estilos específicos para o conteúdo da HomePage */
</style>
